import { Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { UnlinkMerchantStoreUseCase } from 'src/app/core/usecases/merchant-store/unlink-merchant-store.usecase';
import { UnlinkedSuccessfullyPopUpComponent } from './unlinked-successfully-pop-up/unlinked-successfully-pop-up.component';

@Component({
  selector: 'app-unlink-store-pop-up',
  standalone: true,
  imports: [TranslateModule, FormsModule, ReactiveFormsModule, MatCheckboxModule],
  templateUrl: './unlink-store-pop-up.component.html',
  styleUrls: ['./unlink-store-pop-up.component.scss'],
})
export class UnlinkStorePopUpComponent {
  public unlinkForm = new FormGroup({
    acceptanceCheck: new FormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private _unlinkMerchantStoreUseCase: UnlinkMerchantStoreUseCase,
    private _toast: ToastrService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<UnlinkStorePopUpComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { provider: string; storeId: string },
  ) {}

  closeDialog() {
    this._dialogRef.close();
  }

  onSubmit() {
    this._unlinkMerchantStoreUseCase.execute(this.data.storeId).subscribe({
      next: () => {
        this.closeDialog();
        this._dialog.open(UnlinkedSuccessfullyPopUpComponent, {
          width: '450px',
          panelClass: 'linked-store-dialog',
        });
        this._logMixpanelEventUseCase.execute({
          eventName: 'click_unlink_store_successful_message',
          payload: { provider: this.data.provider, storeId: this.data.storeId },
        });
      },
      error: (err) => {
        this._logMixpanelEventUseCase.execute({
          eventName: 'click_unlink_store_failure_message',
          payload: { error: err.error.message },
        });
        this._toast.error(err.error.message);
      },
    });
  }
}
