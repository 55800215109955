import { Clipboard } from '@angular/cdk/clipboard';
import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../base/base.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { APIKeysIntegrationPresenter } from './presenter/api-keys-integration.presenter';
import { APIKeysIntegrationSideEffect } from './presenter/api-keys-integration.sideEffects';
import { APIKeysIntegrationViewEvents } from './presenter/api-keys-integration.viewEvents';
import { APIKeysIntegrationViewState } from './presenter/api-keys-integration.viewState';

@Component({
  selector: 'app-api-keys-integration',
  standalone: true,
  imports: [TranslateModule, LoaderComponent, NgIf],
  templateUrl: './api-keys-integration.component.html',
  styleUrls: ['./api-keys-integration.component.scss'],
  providers: [APIKeysIntegrationPresenter],
})
export class ApiKeysIntegrationComponent extends BaseComponent<
  APIKeysIntegrationPresenter,
  APIKeysIntegrationViewState,
  APIKeysIntegrationViewEvents,
  APIKeysIntegrationSideEffect
> {
  public presenter: APIKeysIntegrationPresenter = inject(APIKeysIntegrationPresenter);

  private _clipboard: Clipboard = inject(Clipboard);

  private _toast: ToastrService = inject(ToastrService);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  onSideEffect(sideEffect: APIKeysIntegrationSideEffect): void {
    switch (sideEffect.type) {
      case 'CopyKey': {
        this._clipboard.copy(this.viewState.apiKey);
        this._toast.info('API Key Copied');
        break;
      }
    }
  }
}
